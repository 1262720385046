<template>
  <div class="news-container">
    <Articles :categoryId="0" />
    <router-view />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Articles from "@/components/app/Articles";

export default {
  name: "News",
  components: { Articles },
  metaInfo() {
    return {
      title:
        this.listSettings.mainSettings.meta_title +
        " | " +
        this.listLang.site_name,
      meta: [
        { charset: "utf-8" },
        {
          name: "description",
          content: this.listSettings.mainSettings.meta_desc,
        },
        {
          name: "keywords",
          content: this.listSettings.mainSettings.meta_keywords,
        },
      ],
    };
  },
  data: () => {
    return {};
  },
  computed: {
    ...mapGetters(["listSettings", "listLang", "translations"]),
  },
  methods: {},
  mounted() {},
};
</script>
